/**
 * Reset some basic elements
 */
body, h1, h2, h3, h4, h5, h6,
p, blockquote, pre, hr,
dl, dd, ol, ul, figure {
  margin: 0;
  padding: 0;
}



/**
 * Basic styling
 */
body {
  font: $base-font-weight #{$base-font-size}/#{$base-line-height} $base-font-family;
  color: $text-color;
  background-color: $background-color;
  -webkit-text-size-adjust: 100%;
  -webkit-font-feature-settings: "kern" 1;
     -moz-font-feature-settings: "kern" 1;
       -o-font-feature-settings: "kern" 1;
          font-feature-settings: "kern" 1;
  font-kerning: normal;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}



/**
 * Set `margin-bottom` to maintain vertical rhythm
 */
h1, h2, h3, h4, h5, h6,
p, blockquote, pre,
ul, ol, dl, figure,
%vertical-rhythm {
  margin-bottom: $spacing-unit / 2;
}



/**
 * `main` element
 */
main {
  display: block; /* Default value of `display` of `main` element is 'inline' in IE 11. */
}



/**
 * Images
 */
img {
  max-width: 100%;
  vertical-align: middle;
}



/**
 * Figures
 */
figure > img {
  display: block;
}

figcaption {
  font-size: $small-font-size;
}



/**
 * Lists
 */
ul, ol {
  margin-left: $spacing-unit;
}

li {
  > ul,
  > ol {
    margin-bottom: 0;
  }
}



/**
 * Headings
 */
h1, h2, h3, h4, h5, h6 {
  font-weight: $base-font-weight;
}



/**
 * Links
 */
a {
  color: $brand-color;
  text-decoration: none;

  &:visited {
    color: darken($brand-color, 15%);
  }

  &:hover {
    color: $text-color;
    text-decoration: underline;
  }

  .social-media-list &:hover {
    text-decoration: none;

    .username {
      text-decoration: underline;
    }
  }
}


/**
 * Blockquotes
 */
blockquote {
  color: $grey-color;
  border-left: 4px solid $grey-color-light;
  padding-left: $spacing-unit / 2;
  @include relative-font-size(1.125);
  letter-spacing: -1px;
  font-style: italic;

  > :last-child {
    margin-bottom: 0;
  }
}



/**
 * Code formatting
 */
pre,
code {
  @include relative-font-size(0.9375);
  border: 1px solid $grey-color-light;
  border-radius: 3px;
  background-color: #eef;
}

code {
  padding: 1px 5px;
}

pre {
  padding: 8px 12px;
  overflow-x: auto;

  > code {
    border: 0;
    padding-right: 0;
    padding-left: 0;
  }
}



/**
 * Wrapper
 */
.wrapper {
  max-width: -webkit-calc(#{$content-width} - (#{$spacing-unit} * 2));
  max-width:         calc(#{$content-width} - (#{$spacing-unit} * 2));
  margin-right: auto;
  margin-left: auto;
  padding-right: $spacing-unit;
  padding-left: $spacing-unit;
  @extend %clearfix;

  @include media-query($on-laptop) {
    max-width: -webkit-calc(#{$content-width} - (#{$spacing-unit}));
    max-width:         calc(#{$content-width} - (#{$spacing-unit}));
    padding-right: $spacing-unit / 2;
    padding-left: $spacing-unit / 2;
  }
}



/**
 * Clearfix
 */
%clearfix:after {
  content: "";
  display: table;
  clear: both;
}



/**
 * Icons
 */

.svg-icon {
    width: 16px;
    height: 16px;
    display: inline-block;
    fill: #{$grey-color};
    padding-right: 5px;
    vertical-align: text-top;
}

.social-media-list {
  li + li {
    padding-top: 5px;
  }
}



/**
 * Tables
 */
table {
  margin-bottom: $spacing-unit;
  width: 100%;
  text-align: $table-text-align;
  color: lighten($text-color, 18%);
  border-collapse: collapse;
  border: 1px solid $grey-color-light;
  tr {
    &:nth-child(even) {
      background-color: lighten($grey-color-light, 6%);
    }
  }
  th, td {
    padding: ($spacing-unit / 3) ($spacing-unit / 2);
  }
  th {
    background-color: lighten($grey-color-light, 3%);
    border: 1px solid darken($grey-color-light, 4%);
    border-bottom-color: darken($grey-color-light, 12%);
  }
  td {
    border: 1px solid $grey-color-light;
  }
}


/*

*/
$tiber: #09383E;
$elephant: #0C383E;
$white: #fff;
header.site-header, .landing-page-content {
  background: #FFF;
  position: relative;
&:after {
  background-image: url('../../assets/interlaced.png');
  background-repeat: repeat;
  background-size: auto;
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0.5;
}
}
// rem font sizing.
html { font-size: 62.5%; }
body.landing { font-size: 1.4rem;
  background: #222;
  position: relative;

  h1   { font-size: 2.4rem; }
  .wrapper {
padding-left: 5px;
padding-right: 5px;
z-index: 5;
    position: relative;
  }
  .site-footer {
    border-top: none;
    position: relative;
    z-index: 6;
  }
 }

.container__item {
    margin: 0 auto -40px;
}

.landing-page-container {
    width: 100%;
    min-height: 100%;
    height: 60rem;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
    overflow: hidden;
    font-family: 'Montserrat', sans-serif;
    color: $tiber;
}

.content__wrapper {
    max-width: 1200px;
    width: 90%;
    height: 100%;
    margin: 0 auto;
    position: relative;
}

.header {
    width: 100%;
    height: 2rem;
    padding: 4.5rem 0;
    display: block;
}

.menu-icon {
    width: 2.5rem;
    height: 1.5rem;
    display: inline-block;
    cursor: pointer;
}

.header__item {
    display: inline-block;
    float: left;
}

.menu-icon__line {
    width: 1.5rem;
    height: 0.2rem;
    background-color: $elephant;
    display: block;

    &:before,
    &:after {
        content: '';
        width: 1.5rem;
        height: 0.2rem;
        background-color: $elephant;
        display: inline-block;
        position: relative;
    }

    &:before {
        left: 0.5rem;
        top: -0.6rem;
    }

    &:after {
        top: -1.8rem;
    }
}

.heading {
    width: 90%;
    font-size: 2rem;
    font-weight: bold;
    margin: 0;
    line-height: 1.7rem;
    margin: 0 auto;
    text-align: center;
}

.social-container {
    width: 7.25rem;
    list-style: none;
    overflow: hidden;
    padding: 0;
    margin: 0;
    float: right;

    .social__icon {
        float: left;
        cursor: pointer;

        &.social__icon--dr {
          margin-left: 0.5rem;
          
          img {
             height: 2rem;
          }
        }
        &.social__icon--in {
          margin-left: 0.75rem;
          
          img {
             height: 2rem;
          }
        }

        &.social__icon--fb {
            img {
                height: 2rem;
                margin: 0rem;
            }
        }
    }
}

.coords {
    font-size: 1rem;
    display: inline-block;
    transform: rotate(-90deg) translateY(50%);
    float: left;
    position: relative;
    top: 40%;
    letter-spacing: 0.2rem;
    left: -11.5rem;
    margin: 0;
}

.ellipses-container {
    width: 50rem;
    height: 50rem;
    border-radius: 50%;
    margin: 0 auto;
    position: relative;
    top: 10.5rem;

    .greeting {
        position: absolute;
        top: 11.6rem;
        left: 13rem;
        right: 0;
        margin: 0 auto;
        text-transform: uppercase;
        letter-spacing: 4rem;
        font-size: 2.2rem;
        font-weight: 400;
        opacity: 0.5;

        &:after{
            content: '';
            width: 0.3rem;
            height: 0.3rem;
            border-radius: 50%;
            display: inline-block;
            background-color: $elephant;
            position: relative;
            top: -0.65rem;
            left: -5.05rem;
        }
    }

}

.ellipses {
    border-radius: 50%;
    position: absolute;
    top: 0;
    border-style: solid;
}

.ellipses__outer--thin {
    width: 100%;
    height: 100%;
    border-width: 1px;
    border-color: rgba(9, 56, 62, 0.1);
    animation: ellipsesOrbit 15s ease-in-out infinite;


    &:after {
      content: "";
      background-repeat: no-repeat;
      background-position: center;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      position: absolute;
      opacity: 0.15;
    }
}

.ellipses__outer--thick {
    width: 99.5%;
    height: 99.5%;
    border-color: $tiber transparent;
    border-width: 2px;
    transform: rotate(-45deg);
    animation: ellipsesRotate 15s ease-in-out infinite;
}

.ellipses__orbit {
    width: 2.5rem;
    height: 2.5rem;
    border-width: 2px;
    border-color: $tiber;
    top: 5rem;
    right: 6.75rem;

    &:before {
        content: '';
        width: 0.7rem;
        height: 0.7rem;
        border-radius: 50%;
        display: inline-block;
        background-color: $tiber;
        margin: 0 auto;
        left: 0;
        right: 0;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);

    }
}

.scroller {
    width: 7.5rem;
    display: inline-block;
    float: right;
    position: relative;
    top: -15%;
    transform: translateY(50%);
    overflow: hidden;


    .page-title {
        font-family: 'Playfair Display', serif;
        letter-spacing: 0.5rem;
        display: inline-block;
        float: left;
        margin-top: 1rem;
    }

    .timeline {
        width: 1.5rem;
        height: 9rem;
        display: inline-block;
        float: right;

        .timeline__unit {
            width: 100%;
            height: 0.1rem;
            display: block;
            background-color: $elephant;
            margin: 0 0 2rem;
            opacity: 0.2;

            &.timeline__unit--active {
                opacity: 1;

                &:after {
                    opacity: 0.2;
                }
            }

            &:after {
                content: '';
                width: 70%;
                height: 0.1rem;
                display: block;
                position: relative;
                float: right;
                background-color: $elephant;
                top: 1rem;
            }
        }
    }
}


@keyframes ellipsesRotate {
    0% {
        transform: rotate(-45deg);
    }

    100% {
        transform: rotate(-405deg);
    }
}

@keyframes ellipsesOrbit {
    0% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(360deg);
    }
}
header.site-header {
border: none;
}

.site-footer {
  background: #222;

}